'use client'

// Next Imports
import Link from 'next/link'

// MUI Imports
import useMediaQuery from '@mui/material/useMediaQuery'
import {styled, useTheme} from '@mui/material/styles'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

// Third-party Imports
import classnames from 'classnames'

// Type Imports
import type {SystemMode} from '@core/types'

// Hook Imports
import {useImageVariant} from '@core/hooks/useImageVariant'
import {useParams} from "next/navigation";
import {useEffect, useState} from "react";
import {getDictionary} from "@/utils/getDictionary";
import type {Locale} from "@configs/i18n";
import {string} from "valibot";
import {getLocalizedUrl} from "@/utils/i18n";

// Styled Components
const MaskImg = styled('img')({
    blockSize: 'auto',
    maxBlockSize: 355,
    inlineSize: '100%',
    position: 'absolute',
    insetBlockEnd: 0,
    zIndex: -1
})

const NotFound = ({mode}: { mode: SystemMode }) => {

    const {lang: locale}: any = useParams()
    const [t, setTranslations]: any = useState({})
    useEffect(() => {
        getDictionary(locale as Locale).then(dictionary => {
            setTranslations(dictionary)
        })
    }, []);

    function translate(key: string, group: string = locale) {
        return t[group]?.[key] || key
    }


    // Hooks
    const theme = useTheme()

    return (t[locale] &&
        <div className='flex items-center justify-center min-bs-[100dvh] relative p-6 overflow-x-hidden'>
            <div className='flex items-center flex-col text-center'>
                <div className='flex flex-col gap-2 is-[90vw] sm:is-[unset] mbe-6'>
                    <Typography className='font-medium text-8xl' color='text.primary'>
                        404
                    </Typography>
                    <Typography variant='h4'>{translate('404', 'errors')}⚠️</Typography>
                    <Typography>{translate('404-descr', 'errors')}!</Typography>
                </div>
                <Button href={getLocalizedUrl('/PannelloAdmin', locale as Locale)} component={Link} variant='contained'>
                    {translate('home-button')}
                </Button>
            </div>
        </div>
    )
}

export default NotFound
